import React from 'react';
import { Container } from 'react-bootstrap';
import HomePage from './components/pages/Home'
import FormPage from './components/pages/Form'
import { BrowserRouter, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <Container fluid>
      <h1>CoGa - Controle de Gastos</h1>
      <hr />
      <BrowserRouter>
        <Switch>
          <Route path="/form/:id" component={FormPage} />
          <Route path="/form" component={FormPage} />
          <Route path="/" component={HomePage} />
        </Switch>
      </BrowserRouter>
    </Container>
  );
}

export default App;

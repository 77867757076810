import axios from 'axios';
import LocalStorageService from './local.storage.service';
// import { push } from 'react-router-dom'
const Endpoints = require('../constants/endpoints');


class BaseService {

    constructor() {
        this.api = axios.create({
            baseURL: Endpoints.BASEURL,
        });

        this.api.interceptors.request.use(async (config) => {
            let token = await LocalStorageService.get(LocalStorageService.TOKEN);
            if (token) {
                config.headers.authorization = token;
            }
            return config;
        }, function (error) {
            console.error(error);
            // return Promise.reject(error);
        });

        this.api.interceptors.response.use(async (response) => {
            // New JWT Token
            if (response && response.data && response.data.newToken) {
                LocalStorageService.save(LocalStorageService.TOKEN, response.data.newToken);
            }

            return response;
        }, function (error) {
            // 401 JWT Expired
            if (error.response.data.message === 'JWT_ERROR') {
                console.log('JWT EXPIRADO!!!');
                window.location = Endpoints.APPURL;
                return;
            }

            console.error(error);
            // return Promise.reject(error);
        });
    }

}

export default BaseService;
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link, Redirect, useParams } from 'react-router-dom';

import ExpenseService from '../../../services/expense.service';

function FormPage() {
    let { id } = useParams();

    const formDefaultValues = {
        description: 'Descrição',
        value: 0,
        howLong: 1,
    };
    const [formValues, setFormValues] = useState(formDefaultValues);
    let [showTableSpinner, setShowTableSpinner] = useState(true);
    let [goToList, setGoToList] = useState(false);

    const handleFormChange = (key, value) => {
        setFormValues({
            ...formValues,
            [key]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowTableSpinner(true);
        ExpenseService.save(formValues, id).then((data) => {
            if (!data.error) {
                setGoToList(true);
            } else {
                setShowTableSpinner(false);
            }
        });

    };

    useEffect(() => {
        if (id) {
            setShowTableSpinner(true);
            ExpenseService.get(id).then(async (data) => {
                if (!data.error) {
                    const expense = data.data;
                    expense.howLong = 1;
                    setFormValues(expense);
                    setShowTableSpinner(false);
                }
            });
        } else {
            setShowTableSpinner(false);
        }
    }, []);

    return (
        <Form onSubmit={handleSubmit}>
            <h3>Cadastrar despesa</h3>

            <Spinner animation="border" variant="primary" hidden={!showTableSpinner} />
            <div hidden={showTableSpinner}>
                <Form.Group as={Row} controlId="formExpense.TextDescription">
                    <Form.Label column sm="2">
                        Descrição
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control value={formValues.description} onChange={(e) => { handleFormChange('description', e.target.value) }} onFocus={(e) => { if (e.target.value === formDefaultValues.description) handleFormChange('description', ''); }} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formExpense.TextValue">
                    <Form.Label column sm="2">
                        Valor
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="number" value={formValues.value} onChange={(e) => { handleFormChange('value', e.target.value) }} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formExpense.TextMonthYear" hidden={id}>
                    <Form.Label column sm="2">
                        Mês/Ano
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="date" onChange={(e) => { handleFormChange('dateStart', e.target.value) }} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formExpense.SwitchPaid" hidden={!id}>
                    <Form.Label column sm="2">
                        Pago?
                    </Form.Label>
                    <Col sm="10">
                        <Form.Check
                            type="switch"
                            id="formExpense.SwitchPaid"
                            label={formValues.paid ? "Sim" : "Não"}
                            onChange={(e) => { handleFormChange('paid', e.target.checked) }}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formExpense.TextHowLong" hidden={id}>
                    <Form.Label column sm="2">
                        Prazo
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control type="range" value={formValues.howLong} onChange={(e) => { handleFormChange('howLong', e.target.value) }} min="1" max="36" />
                    </Col>
                    <Col sm="2">
                        <Form.Control type="number" value={formValues.howLong} onChange={(e) => { handleFormChange('howLong', e.target.value) }} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formExpense.SwitchAction" hidden={!id}>
                    <Form.Label column sm="2">
                        Ação
                    </Form.Label>
                    <Col sm="10">
                        <Form.Check
                            type="switch"
                            id="formExpense.SwitchAction"
                            label={formValues.action === "delete" ? "Excluir" : "Alterar"}
                            onChange={(e) => { handleFormChange('action', e.target.checked ? "delete" : "update") }}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formExpense.SelectDelete" hidden={!id}>
                    <Form.Label column sm="2">
                        {formValues.action === "delete" ? "Excluir" : "Alterar"}
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control as="select" custom onChange={(e) => { handleFormChange('howLong', e.target.value) }}>
                            <option value="one">Somente este</option>
                            <option value="all">O restante da série</option>
                        </Form.Control>
                    </Col>
                </Form.Group>

                <Row>
                    <Col><Button as={Link} to="/" variant="danger" block>Cancelar</Button></Col>
                    <Col><Button type="submit" variant="primary" block>Salvar</Button></Col>
                </Row>
            </div>
            {goToList && <Redirect to='/' />}
        </Form>
    );
}

export default FormPage;

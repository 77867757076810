import BaseService from './base.service';

class ExpenseService extends BaseService {

    // constructor() {
    //     super();
    // }

    async list(year, month) {
        try {
            let res = await this.api.get(`/expense/${year}/${month}`);
            return {error:false, ...res.data}
        } catch (err) {
            return {error:true, ...err.response.data};
        }
    }

    async get(id) {
        try {
            let res = await this.api.get(`/expense/${id}`);
            return {error:false, ...res.data}
        } catch (err) {
            return {error:true, ...err.response.data};
        }
    }

    async save(data, id = undefined) {
        try {
            let res = id ? await this.api.put(`/expense/${id}`, data) : await this.api.post(`/expense`, data);
            return {error:false, ...res.data}
        } catch (err) {
            return {error:true, ...err.response.data};
        }
    }

}

export default new ExpenseService();
class LocalStorageService {

    PREFIX = '@CoGa:';
    USER = 'User';
    TOKEN = 'Token';
    PHONE = 'Phone';

    async save(key, value) {
        localStorage.setItem(this.PREFIX + key, value);
    }

    async get(key) {
        return localStorage.getItem(this.PREFIX + key);
    }

    async removeItem(key) {
        return localStorage.removeItem(this.PREFIX + key);
    }

}

export default new LocalStorageService();
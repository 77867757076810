import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
// import '@sandstreamdev/react-swipeable-list/dist/styles.css';

import ExpenseService from '../../../services/expense.service';

function HomePage() {
    let [expenses, setExpenses] = useState([]);
    let [showTableSpinner, setShowTableSpinner] = useState(true);
    let [formFilter, setFormFilter] = useState({
        month: ((new Date()).getMonth()+1).toString().padStart(2,'0'),
        year: (new Date()).getFullYear()
    });

    let years = [];
    for(let i = 2015; i <= 2030; i++) {
        years.push(i);
    }

    const handleFormChange = (key, value) => {
        let newValues = {
            ...formFilter,
            [key]: value,
        };
        setFormFilter(newValues);
        loadAndShowExpenses(newValues.year, newValues.month);
    };

    function loadAndShowExpenses(year, month) {
        setShowTableSpinner(true);
        setExpenses([]);
        ExpenseService.list(year, month).then((data) => {
            if (!data.error) {
                setExpenses(data.data.expenses);
                setShowTableSpinner(false);
            }
        });
    }

    useEffect(() => {
        loadAndShowExpenses(formFilter.year, formFilter.month);
    }, []);

    return (
        <>
            <Form>
                <Row>
                    <Col>
                        <Form.Group controlId="formExpenseMonth.SelectMonth">
                            <Form.Label>Mês</Form.Label>
                            <Form.Control as="select" custom value={formFilter.month} onChange={(e) => { handleFormChange('month', e.target.value) }}>
                                <option value='01'>Janeiro</option>
                                <option value='02'>Fevereiro</option>
                                <option value='03'>Março</option>
                                <option value='04'>Abril</option>
                                <option value='05'>Maio</option>
                                <option value='06'>Junho</option>
                                <option value='07'>Julho</option>
                                <option value='08'>Agosto</option>
                                <option value='09'>Setembro</option>
                                <option value='10'>Outubro</option>
                                <option value='11'>Novembro</option>
                                <option value='12'>Dezembro</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formExpenseMonth.SelectYear">
                            <Form.Label>Mês</Form.Label>
                            <Form.Control as="select" custom value={formFilter.year} onChange={(e) => { handleFormChange('year', e.target.value) }}>
                                {years.map((item, idx) => (
                                    <option value={item} key={'yearFormFilter-' + idx}>{item}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Descrição</th>
                        <th>Valor</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {expenses.map((item, idx) => (
                        <tr key={idx} className={item.paid ? "bg-success" : ""}>
                            <td><Button as={Link} to={"/form/" + item._id} variant="light" block>{item.description}</Button></td>
                            <td>R$ {item.value}</td>
                            <td>{item.paid ? "Pago" : ""}</td>
                        </tr>
                    ))}
                    <tr hidden={!showTableSpinner}>
                        <td colSpan={3} align='center'><Spinner animation="border" variant="primary" /></td>
                    </tr>
                </tbody>
            </Table>
            <Button as={Link} to="/form" variant="primary" block>Nova Despesa</Button>
        </>
    );
}

export default HomePage;